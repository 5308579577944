<template>
  <div>
    <!-- GRAFIK CABANG -->
    <div class="mt-3 mx-5">
      <v-card
        class="pa-3 rounded-lg"
        :style="{
          backgroundColor: $vuetify.theme.dark ? 'var(--dark-bg-lhp)' : 'var(--light-bg-lhp)',
        }"
      >
        <v-row>
          <v-col cols="12">
            <div class="pa-3">
              <span class="text-subtitle-1 font-weight-bold align-center"> Grafik Cabang </span>
            </div>
          </v-col>
        </v-row>
        <v-divider class="mx-3" />

        <!-- Tambahkan div untuk scroll horizontal -->
        <div class="chart-container mx-3">
          <div class="chart-wrapper">
            <canvas ref="chartCanvas"></canvas>
          </div>
        </div>
      </v-card>
    </div>
    <!-- END GRAFIK CABANG -->
  </div>
</template>

<script>
import Chart from "chart.js";
import services from "@/services"; // Pastikan ini mengarah ke service API yang benar

export default {
  props: {
    chartType: {
      type: String,
      default: "bar",
    },
  },
  data() {
    return {
      chartInstance: null, // Simpan instance chart
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Menagih",
            backgroundColor: "#520e63",
            data: [],
          },
          {
            label: "Tertagih",
            backgroundColor: "#27445D",
            data: [],
          },
        ],
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
          xAxes: [
            {
              ticks: { autoSkip: false },
            },
          ],
          yAxes: [
            {
              ticks: { beginAtZero: true },
            },
          ],
        },
      },
    };
  },
  async mounted() {
    await this.fetchChartData();
  },
  watch: {
    chartData: {
      deep: true,
      handler() {
        this.renderChart();
      },
    },
  },
  methods: {
    async fetchChartData() {
      try {
        const response = await services.grafikCabang();

        if (response.data && response.data.status && response.data.data) {
          const menagihData = response.data.data.menagih || [];
          const tertagihData = response.data.data.tertagih || [];

          if (!Array.isArray(menagihData) || !Array.isArray(tertagihData)) {
            console.warn("Data API tidak dalam format array.");
            return;
          }

          const labels = menagihData.map((item) => String(item.branch_name));
          const menagihValues = menagihData.map((item) => Number(item.nominal) || 0);
          const tertagihValues = tertagihData.map((item) => Number(item.nominal) || 0);

          // Perbarui chartData dengan data API
          this.chartData.labels = labels;
          this.chartData.datasets[0].data = menagihValues;
          this.chartData.datasets[1].data = tertagihValues;
        } else {
          console.error("Status API false atau format data tidak sesuai:", response.data);
        }
      } catch (error) {
        console.error("Gagal mengambil data:", error);
      }
    },
    renderChart() {
      if (this.chartInstance) {
        this.chartInstance.destroy();
      }

      const canvas = this.$refs.chartCanvas;
      if (!canvas) return;
      const ctx = canvas.getContext("2d");

      this.chartInstance = new Chart(ctx, {
        type: this.chartType,
        data: this.chartData,
        options: this.chartOptions,
      });
    },
  },
  beforeDestroy() {
    if (this.chartInstance) {
      this.chartInstance.destroy();
    }
  },
};
</script>
<style scoped>
.chart-container {
  overflow-x: auto;
}

.chart-wrapper {
  min-width: 800px;
  height: 300px;
}
</style>
